import React, { useContext, useEffect, useState } from "react"
import Layout from "@components/Layout/Layout.jsx"
import MainWrap from "@components/Wrap/MainWrap.jsx"
import { Typography } from "@material-ui/core"
import ButtonBack from "@components/Buttons/ButtonBack.jsx"
import Container from "@components/Container/Container.jsx"
import { Form, FormControl, FormGroup, FormFeedback, Label } from "@components/Forms"
import required from "@rules/required.js"
import Button from "@components/Buttons/Button.jsx"
import { makeComponentStyles } from "@components/mixins.js"
import Alert from "@components/Dialog/Alert"
import Image from "@components/Account/Image"
import avatar from "@static/avatar.jpg"
import AuthContext from "@src/auth/context"
import { observer } from "mobx-react-lite"
import { GET_USER } from "@api/modules/auth"
import Loader from "@components/Loading/Loader"
import { EDIT } from "@api/modules/user"
import { navigate, useIntl } from "gatsby-plugin-react-intl"
import Avatar from "@material-ui/core/Avatar"

const styles = ({ breakpoints }) => ({
  form__container: {
    margin: "0 auto",
    width: "50%",
    [breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  file_input: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  file_input__text: {
    marginTop: 20
  },
  profile_image__control: {
    fontSize: 45
  }
})

const validationFilter = item => item === "error"

function Index() {
  const {
    form__container,
    form,
    file_input,
    file_input__text,
    profile_image__control
  } = makeComponentStyles(styles)

  const [body, setBody] = useState({})
  const [user, setUser] = useState({})
  const [alertState, setAlertState] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const { auth } = useContext(AuthContext)
  const intl = useIntl()

  useEffect(async () => {
    setLoading(true)
    try {
      const { data } = await GET_USER()
      setUser(data)
    } catch (e) {
    } finally {
      setTimeout(() => setLoading(false), 500)
    }
  }, [])

  useEffect(() => {
    setBody(body => ({
      ...body,
      name: user.name,
      surname: user.surname
    }))
  }, [user])

  const handleCloseAlert = (_) => {
    navigate("/users/account")
  }

  const observeBody = (key, event) => {
    const getTargetValue = () => {
      switch (event.target.type) {
        case "text":
          return event.target.value
        case "file":
          return event.target.files[0]
      }
    }
    setBody(body => ({
      ...body,
      [key]: getTargetValue()
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const { data } = await EDIT(body)
      auth.setUser(data.body)
      setAlertState(true)
    } catch (err) {
    }
  }

  return (
    <Layout>
      <Container>
        <MainWrap>
          <MainWrap.Header>
            <ButtonBack click={() => navigate("/users/account")} />
            <Typography component={"h1"} variant={"h2"}>
              {intl.formatMessage({ id: "settings" })}
            </Typography>
          </MainWrap.Header>
          <MainWrap.Body>
            <div className={form__container}>
              <Form className={form} onSubmit={handleSubmit} validate filter={validationFilter}>
                <div className={file_input}>
                  <Image
                    type={"label"}
                    component={
                      ({ className }) => <Avatar alt={user.name} src={user.avatar ?? ' '} className={className} />
                    }
                    className={profile_image__control}
                    onChange={observeBody.bind(null, "avatar")}
                  />
                  <Typography className={file_input__text}>
                    {user.name} {user.surname}
                  </Typography>
                </div>
                <FormGroup name={"name-input"}>
                  <Label>{intl.formatMessage({ id: "name" })}</Label>
                  <FormControl
                    onChange={observeBody.bind(null, "name")}
                    value={body.name}
                    validations={[required]}
                  />
                  <FormFeedback />
                </FormGroup>
                <FormGroup name={"surname-input"}>
                  <Label>{intl.formatMessage({ id: "surname" })}</Label>
                  <FormControl
                    validations={[required]}
                    value={body.surname}
                    onChange={observeBody.bind(null, "surname")}
                  />
                  <FormFeedback />
                </FormGroup>
                <Button type="submit">{intl.formatMessage({ id: "save" })}</Button>
              </Form>
            </div>
          </MainWrap.Body>
        </MainWrap>
      </Container>
      <Alert
        state={alertState}
        setState={handleCloseAlert}
      >
        {intl.formatMessage({ id: "settings_success_message" })}
      </Alert>
      <Loader state={isLoading} />
    </Layout>
  )
}

export default observer(Index)
