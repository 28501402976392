import React, {useContext, useEffect} from "react";
import Settings from "@components/Account/pages/settings";
import {navigate} from "gatsby-plugin-react-intl";
import AuthContext from '@src/auth/context'
import {observer} from "mobx-react-lite";

const App = () => {

    const { auth } = useContext(AuthContext);

    if(!auth.isAuth && !auth.isLoading){
        navigate("/users/login");
    }

    return (
        <Settings  />
    )
}

export default observer(App);
