import React, {useContext, useEffect, useState} from 'react';
import {CircularProgress} from "@material-ui/core";
import {makeComponentStyles} from "../mixins";
import clsx from "clsx";

const styles = ({ palette }) => ({
    loader: {
        zIndex: 10000000,
        position: "fixed",
        width: "100%",
        height: "100%",
        background: palette.white.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        left: 0,
        transition: "opacity .25s ease, visibility .25s"
    },
    visible: {
        opacity: 1,
        visibility: "visible"
    },
    hidden: {
        opacity: 0,
        visibility: 'hidden'
    }
})

function Loader({ state }){

    const {
        loader, visible, hidden
    } = makeComponentStyles(styles);

    useEffect(() => {
        if(state) document.body.setAttribute("style", "overflow:hidden")
        else document.body.setAttribute("style", "")
    }, [state])

    return (
        <div className={clsx(
            loader,
            {
                [visible]: state,
                [hidden]: !state
            }
        )}>
            <CircularProgress />
        </div>
    )
}

export default Loader;
