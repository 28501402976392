import $api from "../axios";

const createFormDataBody = body => {
    const fd = new FormData();
    for(const key in body){
        fd.append(key, body[key]);
    }
    return fd;
}

function EDIT(body){
    return $api.post('/user/edit', createFormDataBody(body));
}

export {
    EDIT
}
